@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ComingSoon_comingSoonContainer__2wsWj{background-color:#282c34;height:100vh;width:100vw;margin:0;overflow:hidden;display:grid;align-content:center;color:white;text-align:center;font-family:'Roboto', sans-serif;font-weight:300;line-height:normal}.ComingSoon_comingSoonContainer__2wsWj h1{font-family:'Roboto Light', sans-serif;font-weight:300}.ComingSoon_logo__15Wbv{width:250px;margin:auto}

.JoinMunichGarage_container__3V2Ry{background-color:#282c34;min-height:100vh;width:100vw;margin:0;overflow:hidden;display:grid;align-content:center;color:white;text-align:center;font-family:'Roboto', sans-serif;font-weight:300;line-height:normal}.JoinMunichGarage_container__3V2Ry .JoinMunichGarage_rules__3l7FL{width:80vw;margin:auto;text-align:start;padding-bottom:100px}.JoinMunichGarage_container__3V2Ry .JoinMunichGarage_rules__3l7FL a{color:white}.JoinMunichGarage_container__3V2Ry .JoinMunichGarage_logo__1AGQt{width:250px;margin:auto}

