@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.comingSoonContainer {
  background-color: #282c34;
  height: 100vh;
  width: 100vw;
  margin: 0;
  overflow: hidden;
  display: grid;
  align-content: center;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  line-height: normal;

  h1 {
    font-family: 'Roboto Light', sans-serif;
    font-weight: 300;
  }
}

.logo {
  width: 250px;
  margin: auto;
}
