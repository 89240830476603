.container {
  background-color: #282c34;
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  overflow: hidden;
  display: grid;
  align-content: center;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  line-height: normal;

  .rules {
    width: 80vw;
    margin: auto;
    text-align: start;
    padding-bottom: 100px;

    a {
      color: white;
    }
  }


  .logo {
    width: 250px;
    margin: auto;
  }
}
